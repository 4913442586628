enum QueryKeyNamespace {
  Posts = 'posts',
  Insights = 'insights',
  AdsPlanner = 'adsplanner',
  TextEditor = 'textEditor',
  Dashboard = 'dashboard',
  BrandHub = 'brand-hub',
  ContentPlan = 'contentPlan',
  SocialLogin = 'socialLogin',
}

type Namespace = QueryKeyNamespace | `${QueryKeyNamespace}`;
type Schema = string | string[];
type Keys = readonly unknown[] | unknown;

export type QueryKeyType = ReturnType<typeof QueryKey>;

export const QueryKey = (
  namespace: Namespace,
  schema: Schema,
  keys: Keys = [],
) => {
  const schemas = Array.isArray(schema) ? schema : [schema];

  return [namespace, ...schemas, keys] as const;
};

export const PostsQueryKey = (schema: Schema, keys?: Keys) =>
  QueryKey(QueryKeyNamespace.Posts, schema, keys);

export const InsightsQueryKey = (schema: Schema, keys?: Keys) =>
  QueryKey(QueryKeyNamespace.Insights, schema, keys);

export const DashboardQueryKey = (schema: Schema, keys?: Keys) =>
  QueryKey(QueryKeyNamespace.Dashboard, schema, keys);

export const ContentPlanQueryKey = (schema: Schema, keys?: Keys) =>
  QueryKey(QueryKeyNamespace.ContentPlan, schema, keys);

export const BrandHubQueryKey = (schema: Schema, keys?: Keys) =>
  QueryKey(QueryKeyNamespace.BrandHub, schema, keys);

export const SocialLoginQueryKey = (schema: Schema, keys?: Keys) =>
  QueryKey(QueryKeyNamespace.SocialLogin, schema, keys);
